import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import useInterval from 'use-interval';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { AppRoute } from '../../const';
import { getTime } from '../../main';
import logoAdult from '../../images/dant-plus-logo.png';
import logoKids from '../../images/kids-logo.png';
import styles from './header.module.scss';

function Header() {
  const isMobile = useMediaQuery({
    query: '(max-width: 743px)',
  });

  const timer = getTime(isMobile);
  const [time, setTime] = useState(timer);

  useInterval(() => {
    setTime(getTime(isMobile));
  }, 1000);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleButtonClick = (evt) => {
    evt.preventDefault();
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className={styles.header}>
      <div className={cn(styles.header_top)}>
        <Link
          to={AppRoute.MAIN}
          className={cn(styles.logo_link, styles.logo_link_left)}
        >
          <img
            className={styles.logo_image}
            src={logoAdult}
            width="245"
            height="229"
            aria-label="Вернутся на главную"
          />
        </Link>
        <h1 className={styles.title}>Стоматология «Дантист Плюс»</h1>
        <div className={cn(styles.contacts_wrapper, styles.hidden)}>
          <div className={styles.contacts_phones}>
            <h3 className={styles.contacts_title}>
              Телефон<span className={styles.hidden_tablet}> для связи</span>:
            </h3>
            <a className={styles.contacts_phone} href="tel:+74967524444">
              8 (4967) 52-44-44
            </a>
            <a className={styles.contacts_phone} href="tel:+79651978020">
              8 (965) 197-80-20
            </a>
          </div>
          <div className={styles.contacts_times}>
            <h3 className={styles.contacts_title}>Режим работы:</h3>
            <p className={styles.contacts_text}>Пн-Пт: 10:00-21:00</p>
            <p className={styles.contacts_text}>Сб: 10:00-20:00</p>
            <p className={styles.contacts_text}>Вс: 10:00-17:00</p>
            {/* <p className={cn(styles.contacts_text, styles.contacts_text_red)}>31 декабря - 3 января  - выходной!</p> */}
          </div>
          <div className={styles.contacts_address}>
            <h3 className={styles.contacts_title}>
              Адрес<span className={styles.hidden_tablet}> стоматологии</span>:
            </h3>
            <p className={styles.contacts_text}>Московская обл.</p>
            <p className={styles.contacts_text}>г. Подольск ул. Кирова 61</p>
          </div>
        </div>
        <Link
          to={AppRoute.KIDS}
          className={cn(styles.logo_link, styles.logo_link_right)}
        >
          <img
            className={styles.logo_image}
            src={logoKids}
            width="244"
            height="229"
            aria-label="Вернутся на главную"
          />
        </Link>
      </div>
      <div className={styles.header_timer_wrapper}>
        <div className={styles.header_timer}>{time}</div>
      </div>
      <div className={styles.phones_mobile}>
        <a className={styles.phone_mobile} href="tel:+74967524444">8 (4967) 52-44-44</a>
        <a className={styles.phone_mobile} href="tel:+79651978020">8 (965) 197-80-20</a>
      </div>
      <nav className={styles.nav_wrapper}>
        <div className="container">
          <button
            className={
              isNavOpen
                ? cn(styles.menu_button, styles.menu_button_open)
                : styles.menu_button
            }
            onClick={handleButtonClick}
            aria-label="Открыть меню"
          >
            Меню
          </button>
          <nav>
            <ul
              className={
                isNavOpen
                  ? cn(styles.nav_list, styles.nav_list_open)
                  : styles.nav_list
              }
            >
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.MAIN}
                  className={styles.nav_link}
                  activeClassName={styles.nav_link}
                >
                  О нас
                </NavLink>
              </li>
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.VRACHI}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Специалисты
                </NavLink>
              </li>
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.ADULT}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Услуги
                </NavLink>
              </li>
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.PRICE}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Цены
                </NavLink>
              </li>
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.PROMO}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Скидки и акции
                </NavLink>
              </li>
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.REVIEWS}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Отзывы
                </NavLink>
              </li>
              <li className={cn(styles.nav_item, styles.nav_item_hidden)}>
                <NavLink
                  to={AppRoute.ORDER}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Запись на приём
                </NavLink>
              </li>
              <li className={styles.nav_item}>
                <NavLink
                  to={AppRoute.CONTACTS}
                  className={styles.nav_link}
                  activeClassName={styles.active_link}
                >
                  Контакты
                </NavLink>
              </li>
              <li
                className={cn(styles.nav_item, styles.nav_item_tablet_hidden, styles.nav_item_tablet_hidden_top)}
              >
                <a
                  className={cn(
                    styles.contacts_phone,
                    styles.contacts_phone_menu,
                  )}
                  href="tel:+74967524444"
                >
                  8 (4967) 52-44-44
                </a>
              </li>
              <li
                className={cn(styles.nav_item, styles.nav_item_tablet_hidden)}
              >
                <a
                  className={cn(
                    styles.contacts_phone,
                    styles.contacts_phone_menu,
                  )}
                  href="tel:+79651978020"
                >
                  8 (965) 197-80-20
                </a>
              </li>
              <li
                className={cn(styles.nav_item, styles.nav_item_tablet_hidden)}
              >
                <p className={styles.contacts_text}>
                  г. Подольск ул. Кирова 61
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
    </header>
  );
}

export default Header;
