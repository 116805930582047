import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { AppRoute } from '../../const';
import promoFirst from '../../images/MIS-Osstem.jpg';
import promoSecond from '../../images/prof-chistka.jpg';
import promoThird from '../../images/astra-tech-Straumann.jpg';
import styles from './promo.module.scss';

function Promo({ isMainPage = false }) {

  return (
    <div className={isMainPage ? styles.wrapper : ''}>
      <div className={cn(styles.promo, 'container')}>
        <h2 className={styles.title}>Акции</h2>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={isMainPage ? styles.container : cn(styles.container, styles.container_border)}>
              <img className={styles.image} src={promoFirst} alt='Импланты MIS (Израиль), Osstem (Корея)' width="370" height="199" />
              <div className={styles.description}>
                <h3 className={styles.sub_title}>Импланты MIS (Израиль),<br /> Osstem (Корея)</h3>
                <p className={styles.text}>Имплант + металлокерамическая коронка за <b>70 000 руб.</b></p>
                <p className={styles.text}>Имплант + коронка на основе диоксида циркония за <b>75 000 руб.</b></p>
                <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <div className={isMainPage ? styles.container : cn(styles.container, styles.container_border)}>
              <img className={styles.image} src={promoThird} alt='ASTRA TECH импланты (Швеция)' width="370" height="199" />
              <div className={styles.description}>
                <h3 className={styles.sub_title}>Импланты ASTRA TECH (Швеция),<br /> Straumann (Швейцария)</h3>
                <p className={styles.text}>Имплант + металлокерамическая коронка за <b>110 000 руб.</b></p>
                <p className={styles.text}>Имплант + коронка на основе диоксида циркония за <b>120 000 руб.</b></p>
                <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <div className={isMainPage ? styles.container : cn(styles.container, styles.container_border)}>
              <img className={styles.image} src={promoSecond} alt='Профессиональная чистка зубов (Ультразвук + AirFlow + Полировка)' width="370" height="199" />
              <div className={styles.description}>
                <h3 className={styles.sub_title}>Профессиональная чистка зубов (Ультразвук + AirFlow + Полировка)</h3>
                <p className={styles.text}>Уникальное предложение для посетителей сайта*.</p>
                <p className={styles.text}>Снятие зубных отложений всего за <b>4 500 рублей!</b></p>
                <p className={styles.sub_text}>*Только при записи онлайн.</p>
                <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  );
}

Promo.propTypes = {
  isMainPage: PropTypes.bool,
};


export default Promo;
