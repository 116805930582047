import React from 'react';
import cn from 'classnames';
import styles from './price.module.scss';

function Price() {
  return (
    <div className={cn(styles.prices, 'container')}>
      <h2 className={styles.title}>Прейскурант цен на услуги стоматолога</h2>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>1.1</td>
            <td>Консультация</td>
            <td>бесплатно</td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>Рентген-снимок для пациентов ООО «Дантист Плюс»</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>1.3</td>
            <td>Рентген-снимок для пациентов иных мед.учреждений</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>1.4</td>
            <td>Осмотр после лечения в других мед.учреждениях</td>
            <td>600,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Цены на терапевтическое лечение</h3>
      <h4 className={styles.subtitle}>Анестезия</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>2.2</td>
            <td>Анестезия (Ultrakain, Articaine+Epinefrine, Mepivacainum), проводниковая</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.3</td>
            <td>Анестезия (Ultrakain, Articaine+Epinefrine, Mepivacainum), проводниковая с пониженным содержанием адреналина</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.4</td>
            <td>Анестезия (Ultrakain, Articaine+Epinefrine, Mepivacainum), инфильтрационная без адреналина</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.5</td>
            <td>Анестезия (Ultrakain, Articaine+Epinefrine, Mepivacainum), инфильтрационная с пониженным содержанием адреналина</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.6</td>
            <td>Анестезия (Ultrakain, Articaine+Epinefrine, Mepivacainum), инфильтрационная</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.7</td>
            <td>Анестезия (Убистезин форте)</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.8</td>
            <td>Анестезия (аппликационная)</td>
            <td>400,00 р</td>
          </tr>
          <tr>
            <td>2.9</td>
            <td>Инъекция противовосполительная</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.10</td>
            <td>Инъекция витаминная</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>2.11</td>
            <td>Инъекция «Траумель С» 1 посещение</td>
            <td>1 100,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Лечение</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>3.1</td>
            <td>Формирование полости при кариесе</td>
            <td>750,00 р</td>
          </tr>
          <tr>
            <td>3.2</td>
            <td>Формирование полости при периодонтите (пульпите)</td>
            <td>750,00 р</td>
          </tr>
          <tr>
            <td>3.3</td>
            <td>Ультразвуковое формирование полости при кариесе без применения анестезии (безболезненно)</td>
            <td>1 750,00 р</td>
          </tr>
          <tr>
            <td>3.4</td>
            <td>Удаление старой пломбы</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>3.5</td>
            <td>Наложение изолирующей прокладки</td>
            <td>850,00 р</td>
          </tr>
          <tr>
            <td>3.6</td>
            <td>Наложение лечебной прокладки</td>
            <td>850,00 р</td>
          </tr>
          <tr>
            <td>3.7</td>
            <td>Прокладка световая кальцесодержащая</td>
            <td>950,00 р</td>
          </tr>
          <tr>
            <td>3.8</td>
            <td>Наложение девитализирующей пасты</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>3.9</td>
            <td>Постановка временной пломбы</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>3.10</td>
            <td>Экстирпация пульпы из одного канала</td>
            <td>750,00 р</td>
          </tr>
          <tr>
            <td>3.11</td>
            <td>Механическая и медикамент. Обработка 1 канала (пульпит)</td>
            <td>950,00 р</td>
          </tr>
          <tr>
            <td>3.12</td>
            <td>Механическая и медикамент. Обработка 1 канала (периодонтит)</td>
            <td>950,00 р</td>
          </tr>
          <tr>
            <td>3.13</td>
            <td>Пломбировка 1 канала (временная)</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>3.14</td>
            <td>Пломбировка 1 канала пастой (Эндометазон)</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>3.15</td>
            <td>Пломбировка 1 канала пастой (на основе резорцин-формалина)</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>3.16</td>
            <td>Пломбировка 1 канала с гуттаперчивыми штифтами</td>
            <td>1 400,00 р</td>
          </tr>
          <tr>
            <td>3.17</td>
            <td>Постановка анкерного штифта</td>
            <td>1 550,00 р</td>
          </tr>
          <tr>
            <td>3.18</td>
            <td>Постановка титанового штифта</td>
            <td>2 350,00 р</td>
          </tr>
          <tr>
            <td>3.19</td>
            <td>Постановка стекловолоконного штифта</td>
            <td>2 650,00 р</td>
          </tr>
          <tr>
            <td>3.20</td>
            <td>Лечение каласептом</td>
            <td>1 350,00 р</td>
          </tr>
          <tr>
            <td>3.21</td>
            <td>Лечение TrApeks-Gel (Метапекс) 1 канала</td>
            <td>1 350,00 р</td>
          </tr>
          <tr>
            <td>3.22</td>
            <td>Коффердам</td>
            <td>850,00 р</td>
          </tr>
          <tr>
            <td>3.23</td>
            <td>Оптрагейт</td>
            <td>750,00 р</td>
          </tr>
          <tr>
            <td>3.24</td>
            <td>Закрытие перфорации Pro - root</td>
            <td>3 000,00 р</td>
          </tr>
          <tr>
            <td>3.25</td>
            <td>Временное восстановление стенок зуба</td>
            <td>1 500,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Распломбировка</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>4.1</td>
            <td>Пломбированного гутаперчей</td>
            <td>1 150,00 р</td>
          </tr>
          <tr>
            <td>4.2</td>
            <td>Пломбированного эндометазоном, АН-26</td>
            <td>1 300,00 р</td>
          </tr>
          <tr>
            <td>4.3</td>
            <td>Пломбированного резорцин-формалиновой пастой</td>
            <td>1 700,00 р</td>
          </tr>
          <tr>
            <td>4.4</td>
            <td>Пломбированного цементом</td>
            <td>1 550,00 р</td>
          </tr>
          <tr>
            <td>4.5</td>
            <td>Частичное распломбирование корневого канала</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>4.6</td>
            <td>Ультразвуковая распломбировка канала</td>
            <td>900,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Пломбировочный материал</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>5.1</td>
            <td>Реставрация художественная одного фронтального зуба (EsCom)</td>
            <td>8 000,00 р</td>
          </tr>
          <tr>
            <td>5.2</td>
            <td>Реставрация художественная одного бокового зуба (EsCom)</td>
            <td>7 800,00 р</td>
          </tr>
          <tr>
            <td>5.3</td>
            <td>Реставрация художественная одного фронтального зуба (Filtek)</td>
            <td>10 500,00 р</td>
          </tr>
          <tr>
            <td>5.4</td>
            <td>Реставрация художественная одного бокового зуба (Filtek)</td>
            <td>10 500,00 р</td>
          </tr>
          <tr>
            <td>5.5</td>
            <td>Свето/отв. Композит 1 поверхность (харизма)</td>
            <td>3 550,00 р</td>
          </tr>
          <tr>
            <td>5.6</td>
            <td>Свето/отв. Композит 2 поверхности (харизма)</td>
            <td>4 450,00 р</td>
          </tr>
          <tr>
            <td>5.7</td>
            <td>Свето/отв. Композит 3 поверхности (харизма)</td>
            <td>4 750,00 р</td>
          </tr>
          <tr>
            <td>5.8</td>
            <td>Свето/отв. Композит 1 поверхность (филтек)</td>
            <td>4 450,00 р</td>
          </tr>
          <tr>
            <td>5.9</td>
            <td>Свето/отв. Композит 2 поверхности (филтек)</td>
            <td>5 300,00 р</td>
          </tr>
          <tr>
            <td>5.10</td>
            <td>Свето/отв. Композит 3 поверхности (филтек)</td>
            <td>6 000,00 р</td>
          </tr>
          <tr>
            <td>5.11</td>
            <td>Свето/отв. Композит 1 поверхность (эстелайт)</td>
            <td>4 750,00 р</td>
          </tr>
          <tr>
            <td>5.12</td>
            <td>Свето/отв. Композит 2 поверхности (эстелайт)</td>
            <td>5 750,00 р</td>
          </tr>
          <tr>
            <td>5.13</td>
            <td>Свето/отв. Композит 3 поверхности (эстелайт)</td>
            <td>6 700,00 р</td>
          </tr>
          <tr>
            <td>5.14</td>
            <td>Свето/отв.жидкотекучий композит</td>
            <td>4 000,00 р</td>
          </tr>
          <tr>
            <td>5.15</td>
            <td>Стеклоиномерный композит «Vitremer»</td>
            <td>4 450,00 р</td>
          </tr>
          <tr>
            <td>5.16</td>
            <td>Стеклоиномерный цемент 1 поверхность</td>
            <td>1 550,00 р</td>
          </tr>
          <tr>
            <td>5.17</td>
            <td>Стеклоиномерный цемент 2 поверхности</td>
            <td>2 100,00 р</td>
          </tr>
          <tr>
            <td>5.18</td>
            <td>Стеклоиномерный цемент 3 поверхности</td>
            <td>2 350,00 р</td>
          </tr>
          <tr>
            <td>5.19</td>
            <td>Герметизация фисур (жевательный пов-ти) 1 зуба (профилактика кариеса)</td>
            <td>2 350,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Эндодонтическое лечение при периодонтите</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>6.1</td>
            <td>Одноканальный зуб (первый прием)</td>
            <td>4 450,00 р</td>
          </tr>
          <tr>
            <td>6.2</td>
            <td>Одноканальный зуб (второй прием)</td>
            <td>3 700,00 р</td>
          </tr>
          <tr>
            <td>6.3</td>
            <td>Одноканальный зуб (третий прием)</td>
            <td>3 750,00 р</td>
          </tr>
          <tr>
            <td>6.4</td>
            <td>Двухканальный зуб (первый прием)</td>
            <td>6 750,00 р</td>
          </tr>
          <tr>
            <td>6.5</td>
            <td>Двухканальный зуб (второй прием)</td>
            <td>6 000,00 р</td>
          </tr>
          <tr>
            <td>6.6</td>
            <td>Двухканальный зуб (третий прием)</td>
            <td>6 100,00 р</td>
          </tr>
          <tr>
            <td>6.7</td>
            <td>Трехканальный зуб (первый прием)</td>
            <td>9 050,00 р</td>
          </tr>
          <tr>
            <td>6.8</td>
            <td>Трехканальный зуб (второй прием)</td>
            <td>8 300,00 р</td>
          </tr>
          <tr>
            <td>6.9</td>
            <td>Трехканальный зуб (третий прием)</td>
            <td>8 450,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Эндодонтическое лечение при пульпите</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>6.10</td>
            <td>Общее лечение одноканального зуба</td>
            <td>5 900,00 р</td>
          </tr>
          <tr>
            <td>6.11</td>
            <td>Общее лечение двухканального зуба</td>
            <td>9 000,00 р</td>
          </tr>
          <tr>
            <td>6.12</td>
            <td>Общее лечение трехканального зуба</td>
            <td>12 100,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Гигиена</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>6.1</td>
            <td>Удаление зубных отложений ультразвуковым аппаратом (1 зуб) + air flow</td>
            <td>450,00 р</td>
          </tr>
          <tr>
            <td>6.2</td>
            <td>Удаление зубных отложений ультразвуковым аппаратом (1 сегмент) +air flow</td>
            <td>2 200,00 р</td>
          </tr>
          <tr>
            <td>6.3</td>
            <td>Удаление зубных отложений ультразвуковым аппаратом (полости рта) +air flow</td>
            <td>9 900,00 р</td>
          </tr>
          <tr>
            <td>6.15</td>
            <td>Отбеливание гелевое (1 зуб)</td>
            <td>2 200,00 р</td>
          </tr>
          <tr>
            <td>6.16</td>
            <td>Изготовление индивидуальной каплы для домашнего отбеливания (2 челюсти)</td>
            <td>10 150,00 р</td>
          </tr>
          <tr>
            <td>6.17</td>
            <td>Полировка пастами (1 зуб)</td>
            <td>400,00 р</td>
          </tr>
          <tr>
            <td>6.18</td>
            <td>Полировка пастами (1 челюсть)</td>
            <td>1 800,00 р</td>
          </tr>
          <tr>
            <td>6.19</td>
            <td>Покрытие фторосодержащим лаком (1 зуб)</td>
            <td>500,00 р</td>
          </tr>
          <tr>
            <td>6.20</td>
            <td>Покрытие фторосодержащим лаком (1 челюсть)</td>
            <td>1 600,00 р</td>
          </tr>
          <tr>
            <td>6.21</td>
            <td>Снятие чувствительности световым десенсетайзером (Япония) (1 зуб)</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>6.22</td>
            <td>Снятие чувствительности десенсетайзером (1 зуб)</td>
            <td>500,00 р</td>
          </tr>
          <tr>
            <td>6.23</td>
            <td>Снятие чувствительности seal&Protect (сиал протект) (1 зуб)</td>
            <td>400,00 р</td>
          </tr>
          <tr>
            <td>6.24</td>
            <td>Снятие чувствительности эмаль-герметизирующий ликвид (1 зуб)</td>
            <td>450,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Хирургическая стоматология</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>7.1</td>
            <td>Удаление молочного зуба</td>
            <td>1 450,00 р</td>
          </tr>
          <tr>
            <td>7.1.1</td>
            <td>Удаление нерезорбированного зуба</td>
            <td>2 500,00 р</td>
          </tr>
          <tr>
            <td>7.1.2</td>
            <td>Удаление подвижного зуба</td>
            <td>2 500,00 р</td>
          </tr>
          <tr>
            <td>7.1.3</td>
            <td>Удаление резорбированного зуба</td>
            <td>3 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.4</td>
            <td>Удаление 1 категории сложности</td>
            <td>2 800,00 р</td>
          </tr>
          <tr>
            <td>7.1.5</td>
            <td>Удаление 2 категории сложности (разрушенные однокорневые)</td>
            <td>4 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.6</td>
            <td>Удаление 3 категории сложности (разрушенные многокорневые)</td>
            <td>6 500,00 р</td>
          </tr>
          <tr>
            <td>7.1.7</td>
            <td>Удаление 4 категории сложности (разрушенные, резорциненыеб с применением бор-машины)</td>
            <td>8 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.8</td>
            <td>Удаление зуба мудрости</td>
            <td>9 500,00 р</td>
          </tr>
          <tr>
            <td>7.1.9</td>
            <td>Удаление ретинированного (дистапированного) зуба</td>
            <td>9 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.10</td>
            <td>Удаление ретинированного (дистапированного) зуба 1 категории сложности с откидыванием лоскута</td>
            <td>9 100,00 р</td>
          </tr>
          <tr>
            <td>7.1.11</td>
            <td>Удаление ретинированного (дистапированного) зуба 2 категории сложности с альвеолотомией</td>
            <td>11 300,00 р</td>
          </tr>
          <tr>
            <td>7.1.12</td>
            <td>Удаление ретинированного (дистапированного) зуба 3 категории сложности с гемисекцией и глубокой альвеолотомией</td>
            <td>14 500,00 р</td>
          </tr>
          <tr>
            <td>7.2</td>
            <td>Медикаментозная обработка антисептиками</td>
            <td>500,00 р</td>
          </tr>
          <tr>
            <td>7.2.1</td>
            <td>Лечение альвеолита</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>7.2.3</td>
            <td>Вскрытие абсцесса, периостомия, дренирование</td>
            <td>3 350,00 р</td>
          </tr>
          <tr>
            <td>7.2.4</td>
            <td>Закрытие перфорации верхнечелюстной пазухи</td>
            <td>5 300,00 р</td>
          </tr>
          <tr>
            <td>7.2.5</td>
            <td>Гемисекция, ампутация корня</td>
            <td>7 200,00 р</td>
          </tr>
          <tr>
            <td>7.2.6</td>
            <td>Коррекция альвеолярного отростка в области одного зуба (альвелотомия, экзостозектомия)</td>
            <td>2 100,00 р</td>
          </tr>
          <tr>
            <td>7.2.7</td>
            <td>Изготовление и наложение послеоперационной давящей повязки</td>
            <td>1 000,00 р</td>
          </tr>
          <tr>
            <td>7.2.8</td>
            <td>Удаление ретенционной кисты</td>
            <td>10 700,00 р</td>
          </tr>
          <tr>
            <td>7.2.9</td>
            <td>Удаление экзостоза</td>
            <td>2 800,00 р</td>
          </tr>
          <tr>
            <td>7.2.10</td>
            <td>Перикоронаротомия (иссечение капюшона)</td>
            <td>2 300,00 р</td>
          </tr>
          <tr>
            <td>7.3</td>
            <td>Пластика бокового тяжа слизистой оболочки</td>
            <td>5 100,00 р</td>
          </tr>
          <tr>
            <td>7.3.1</td>
            <td>Пластика уздечки губы или языка</td>
            <td>6 100,00 р</td>
          </tr>
          <tr>
            <td>7.3.2</td>
            <td>Премидикация</td>
            <td>2 900,00 р</td>
          </tr>
          <tr>
            <td>7.3.3</td>
            <td>Кюретаж лунки</td>
            <td>1 100,00 р</td>
          </tr>
          <tr>
            <td>7.3.4</td>
            <td>Наложение/снятие одного шва</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>7.3.5</td>
            <td>Дренаж мягких тканей</td>
            <td>1 600,00 р</td>
          </tr>
          <tr>
            <td>7.3.6</td>
            <td>Цистэктомия с резекцией верхушки 1 корня</td>
            <td>14 100,00 р</td>
          </tr>
          <tr>
            <td>7.3.7</td>
            <td>Каждый последующий корень при резекции верхушки корня, цистэктомии</td>
            <td>2 800,00 р</td>
          </tr>
          <tr>
            <td>7.3.8</td>
            <td>Операции цистэктомии при (или после) удалении зуба</td>
            <td>2 800,00 р</td>
          </tr>
          <tr>
            <td>7.4</td>
            <td>Коагуляция десны (1 зуб)</td>
            <td>1 700,00 р</td>
          </tr>
          <tr>
            <td>7.4.1</td>
            <td>Профилактика альвеолита (неоконус, альвожил, альвостаз, йодоформная турунда)</td>
            <td>950,00 р</td>
          </tr>
          <tr>
            <td>7.4.2</td>
            <td>Снятие швов</td>
            <td>450,00 р</td>
          </tr>
          <tr>
            <td>7.4.3</td>
            <td>Изготовление защитной пластинки из пластмассы</td>
            <td>4 500,00 р</td>
          </tr>
          <tr>
            <td>7.4.4</td>
            <td>Удаление интегрированого имплантанта</td>
            <td>14 500,00 р</td>
          </tr>
          <tr>
            <td>7.4.5</td>
            <td>Удаление фактуры зуба</td>
            <td>950,00 р</td>
          </tr>
          <tr>
            <td>7.4.6</td>
            <td>Перевязка после хирургического вмешательства</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>7.4.9</td>
            <td>Операция закрытия сообщения полости рта с верхнечелюстной пазухой</td>
            <td>9 000,00 р</td>
          </tr>
          <tr>
            <td>7.4.10</td>
            <td>Вестибулопластика предверия полости рта</td>
            <td>15 500,00 р</td>
          </tr>
          <tr>
            <td>7.4.11</td>
            <td>Операция увеличения обножения коронковой части зуба (гингивэктомия)</td>
            <td>3 500,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Подготовка к имплантации</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>7.5</td>
            <td>Операция синус-лифтинг (закрытый метод) ***</td>
            <td>27 000,00 р</td>
          </tr>
          <tr>
            <td>7.5.1</td>
            <td>Операция синус-лифтинг (открытый метод) ***</td>
            <td>39 000,00 р</td>
          </tr>
          <tr>
            <td>7.5.2</td>
            <td>Операция пересадки костного блока ***</td>
            <td>40 500,00 р</td>
          </tr>
          <tr>
            <td>7.5.3</td>
            <td>Операция пересадки свободного десневого аутотрансплантанта ***</td>
            <td>13 500,00 р</td>
          </tr>
          <tr>
            <td>7.5.4</td>
            <td>Операция расщепления костного гребня ***</td>
            <td>21 000,00 р</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.text}>*** Материалы для данных операций оплачиваются отдельно согласно прейскуранта, в зависимости от количества расходного материала.</p>
      <h3 className={styles.title}>Использование дополнительных материалов</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>7.6</td>
            <td>Bi-oss, (0,5 гр)</td>
            <td>12 000,00 р</td>
          </tr>
          <tr>
            <td>7.6.1</td>
            <td>Bi-oss, (1,0 гр)</td>
            <td>20 000,00 р</td>
          </tr>
          <tr>
            <td>7.6.2</td>
            <td>Bi-oss, (1,5 гр)</td>
            <td>30 000,00 р</td>
          </tr>
          <tr>
            <td>7.6.3</td>
            <td>Bio-gade (мембрана) 2,5*2,5</td>
            <td>20 000,00 р</td>
          </tr>
          <tr>
            <td>7.6.4</td>
            <td>Bio-gade (мембрана) 16*22</td>
            <td>18 000,00 р</td>
          </tr>
          <tr>
            <td>7.6.5</td>
            <td>Bio-gade (мембрана) 30*40</td>
            <td>29 000,00 р</td>
          </tr>
          <tr>
            <td>7.6.6</td>
            <td>Использование Коллапана</td>
            <td>5 800,00 р</td>
          </tr>
          <tr>
            <td>7.6.7</td>
            <td>Использование колагенового материала Стимул-ОС</td>
            <td>3 500,00 р</td>
          </tr>
          <tr>
            <td>7.6.8</td>
            <td>Посадка костного материала (bi-oss)</td>
            <td>7 700,00 р</td>
          </tr>
          <tr>
            <td>7.6.9</td>
            <td>Посадка мембраны (bio-gade)</td>
            <td>6 300,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Хирургический раздел</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>7.1</td>
            <td>Установка имплантата Alpha-Bio</td>
            <td>67 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.2</td>
            <td>Установка имплантата MIS</td>
            <td>67 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.3</td>
            <td>Установка имплантата ASTRA TECH, Straumann</td>
            <td>105 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.4</td>
            <td>Второй хирургический этап: раскрытие имплантата, установка формирователя десны Alpha-Bio</td>
            <td>7 200,00 р</td>
          </tr>
          <tr>
            <td>7.1.5</td>
            <td>Второй хирургический этап: раскрытие имплантата, установка формирователя десны MIS</td>
            <td>7 200,00 р</td>
          </tr>
          <tr>
            <td>7.1.6</td>
            <td>Второй хирургический этап: раскрытие имплантата, установка формирователя десны ASTRA TECH</td>
            <td>15 000,00 р</td>
          </tr>
          <tr>
            <td>7.1.6</td>
            <td>Второй хирургический этап: раскрытие имплантата, установка формирователя десны Straumann</td>
            <td>15 000,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Ортопедический раздел</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>7.2</td>
            <td>Временная коронка на импланте</td>
            <td>14 000,00 р</td>
          </tr>
          <tr>
            <td>7.2.1</td>
            <td>Временный абатмент</td>
            <td>9 700,00 р</td>
          </tr>
          <tr>
            <td>7.2.2</td>
            <td>Коронка металлокерамическая на имплантате ASTRA TECH, Straumann (без стоимости абатмента)</td>
            <td>55 000,00 р</td>
          </tr>
          <tr>
            <td>7.2.3</td>
            <td>Коронка металлокерамическая на имплант Alpha-bio, MIS (без стоимости абатмента)</td>
            <td>43 000,00 р</td>
          </tr>
          <tr>
            <td>7.2.4</td>
            <td>Коронка из оксида циркония на имплантат Alpha-bio, MIS (без стоимости абатмента)</td>
            <td>48 500,00 р</td>
          </tr>
          <tr>
            <td>7.2.5</td>
            <td>Коронка из оксида циркония на имплант ASTRA TECH, Straumann (без стоимости абатмента)</td>
            <td>60 500,00 р</td>
          </tr>
          <tr>
            <td>7.2.6</td>
            <td>Коронка на оксиде циркония с опорой на имплант</td>
            <td>48 500,00 р</td>
          </tr>
          <tr>
            <td>7.2.7</td>
            <td>Коронка керамическая на металлическом каркасе</td>
            <td>38 000,00 р</td>
          </tr>
          <tr>
            <td>7.2.8</td>
            <td>Multi Unit Miss</td>
            <td>19 000,00 р</td>
          </tr>
          <tr>
            <td>7.2.9</td>
            <td>Multi Unit Straumann (ASTRA TECH)</td>
            <td>29 500,00 р</td>
          </tr>
          <tr>
            <td>7.2.10</td>
            <td>Стандартный абатмент Alpha-bio, MIS</td>
            <td>12 700,00 р</td>
          </tr>
          <tr>
            <td>7.2.11</td>
            <td>Стандартный абатмент ASTRA TECH, Straumann</td>
            <td>19 500,00 р</td>
          </tr>
          <tr>
            <td>7.3</td>
            <td>Индивидуальный абатмент</td>
            <td>20 700,00 р</td>
          </tr>
          <tr>
            <td>7.3.1</td>
            <td>Индивидуальный абатмент из диоксида циркония</td>
            <td>27 000,00 р</td>
          </tr>
          <tr>
            <td>7.3.2</td>
            <td>Фрезеровка абатмента (ортопедическая часть)</td>
            <td>7 500,00 р</td>
          </tr>
          <tr>
            <td>7.3.3</td>
            <td>Изготовление индивидуальной ложки под имплантат</td>
            <td>6 700,00 р</td>
          </tr>
          <tr>
            <td>7.3.4</td>
            <td>Направляющий шаблон</td>
            <td>7 500,00 р</td>
          </tr>
          <tr>
            <td>7.3.5</td>
            <td>Навигационный шаблон на 1-10 имплантатов</td>
            <td>11 550,00 - 26 500 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Цены на ортопедическое лечение</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>8.1</td>
            <td>Слепки альгинат</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>8.2</td>
            <td>Слепки селикон</td>
            <td>1 100,00 р</td>
          </tr>
          <tr>
            <td>8.3</td>
            <td>Цельнолитая вкладка с патрицей «УНИ» с фиксацией</td>
            <td>10 700,00 р</td>
          </tr>
          <tr>
            <td>8.4</td>
            <td>Серебрянная/металлическая культевая вкладка с фиксацией</td>
            <td>8 500,00 р</td>
          </tr>
          <tr>
            <td>8.5</td>
            <td>Серебрянная/металлическая разборная культевая вкладка с фиксацией</td>
            <td>9 600,00 р</td>
          </tr>
          <tr>
            <td>8.6</td>
            <td>Регистрация прикуса</td>
            <td>1 500,00 р</td>
          </tr>
          <tr>
            <td>8.7</td>
            <td>Капа индивидуальная</td>
            <td>9 700,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Изготовление несъемных протезов</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>9.0</td>
            <td>Временная пластмассовая коронка (прямым методом)</td>
            <td>3 450,00 р</td>
          </tr>
          <tr>
            <td>9.0.1</td>
            <td>Временная пластмассовая коронка (лабораторное изготовление)</td>
            <td>5 700,00 р</td>
          </tr>
          <tr>
            <td>9.3.1</td>
            <td>Коронка цельнолитая</td>
            <td>7 950,00 р</td>
          </tr>
          <tr>
            <td>9.3.2</td>
            <td>Коронка цельнолитая с напылением</td>
            <td>9 500,00 р</td>
          </tr>
          <tr>
            <td>9.3.3</td>
            <td>Коронка цельнолитая с корневой фиксацией (2 в 1)</td>
            <td>18 600,00 р</td>
          </tr>
          <tr>
            <td>9.7</td>
            <td>Коронка металлокерамика (Duceram +) Германия (включая препарирование зуба)</td>
            <td>14 650,00 р</td>
          </tr>
          <tr>
            <td>9.7.1</td>
            <td>Коронка металлокерамика (Duceram +) Германия (Промежуточная часть)</td>
            <td>12 800,00 р</td>
          </tr>
          <tr>
            <td>9.7.2</td>
            <td>Коронка металлокерамика (Повышенной эстетики, на печатном лазерном каркасе)</td>
            <td>19 400,00 р</td>
          </tr>
          <tr>
            <td>9.7.3</td>
            <td>Коронка м/к с плечевой массой</td>
            <td>16 500,00 р</td>
          </tr>
          <tr>
            <td>9.7.4</td>
            <td>Цирконевая вкладка под конструкцию</td>
            <td>22 000,00 р</td>
          </tr>
          <tr>
            <td>9.7.5</td>
            <td>Коронка металлокерамика под Бюгельные протезы</td>
            <td>17 100,00 р</td>
          </tr>
          <tr>
            <td>9.7.6</td>
            <td>Коронка диоксид циркония</td>
            <td>30 000,00 р</td>
          </tr>
          <tr>
            <td>9.7.7</td>
            <td>Коронка, винир (E-max)</td>
            <td>30 000,00 р</td>
          </tr>
          <tr>
            <td>9.7.8</td>
            <td>Вкладка терапевтическая (E-max)</td>
            <td>23 500,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Изготовление съемных протезов</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>10.1</td>
            <td>Починка протеза</td>
            <td>8 500,00 р</td>
          </tr>
          <tr>
            <td>10.2</td>
            <td>Приварка одного зуба (кламмера) (каждый последующий зуб 1200р.)</td>
            <td>4 500,00 р</td>
          </tr>
          <tr>
            <td>10.3</td>
            <td>Перебазировка съемного протеза, изготовленного до 1 года</td>
            <td>6 300,00 р</td>
          </tr>
          <tr>
            <td>10.4</td>
            <td>Перебазировка съемного протеза, изготовленного более 1 года</td>
            <td>7 200,00 р</td>
          </tr>
          <tr>
            <td>10.5</td>
            <td>Перебазировка съемного протеза, изготовленного в других мед. уч. до 1 года и более 1 года</td>
            <td>12 700,00 р</td>
          </tr>
          <tr>
            <td>10.6</td>
            <td>Частично-съемный протез (импортный гарнитур)</td>
            <td>28 000,00 р</td>
          </tr>
          <tr>
            <td>10.7</td>
            <td>Полный съемный протез (импортный гарнитур, импортная пластмасса)</td>
            <td>31 000,00 р</td>
          </tr>
          <tr>
            <td>10.8</td>
            <td>Съемный протез на патрицах «УНИ»</td>
            <td>32 500,00 р</td>
          </tr>
          <tr>
            <td>10.9</td>
            <td>Гибкий бюгельный протез (Итальянская технология)</td>
            <td>56 000,00 р</td>
          </tr>
          <tr>
            <td>10.10</td>
            <td>Нейлоновый гибкий полный (частичный) съемный протез (Итальянская технология)</td>
            <td>56 000,00 р</td>
          </tr>
          <tr>
            <td>10.11</td>
            <td>Частично-съемный акриловый протез с нейлоновыми кламмерами (Итальянская технология)</td>
            <td>50 000,00 р</td>
          </tr>
          <tr>
            <td>10.12</td>
            <td>Бюгельный протез на замковых креплениях (бредент)</td>
            <td>92 000,00 р</td>
          </tr>
          <tr>
            <td>10.13</td>
            <td>Гибкий медиат-протез 1 зуб (Итальянская технология)</td>
            <td>19 000,00 р</td>
          </tr>
          <tr>
            <td>10.14</td>
            <td>Прикусной восковой шаблон</td>
            <td>3 000,00 р</td>
          </tr>
          <tr>
            <td>10.15</td>
            <td>Индивидуальная ложка</td>
            <td>3 000,00 р</td>
          </tr>
          <tr>
            <td>10.16</td>
            <td>Медиат-протез акриловый 1-3 зуба</td>
            <td>14 000,00 р</td>
          </tr>
          <tr>
            <td>10.17</td>
            <td>Профессиональная чистка нейлонового протеза</td>
            <td>5 500,00 р</td>
          </tr>
          <tr>
            <td>10.18</td>
            <td>Армирование </td>
            <td>6 800,00 р</td>
          </tr>
          <tr>
            <td>10.19</td>
            <td>Бюгельный протез кламмерная фиксация</td>
            <td>82 000,00 р</td>
          </tr>
          <tr>
            <td>10.20</td>
            <td>Съемный протез, балочное крепление на имплантах</td>
            <td>152 000,00 р</td>
          </tr>
          <tr>
            <td>10.21</td>
            <td>Съемный протез, балочное крепление (замковый, регильный)</td>
            <td>171 000,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Фиксация протезов</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>11.1</td>
            <td>Временная фиксация одной коронки</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>11.2</td>
            <td>Фиксация одной коронки на стеклоиномерный цемент</td>
            <td>1 100,00 р</td>
          </tr>
          <tr>
            <td>11.3</td>
            <td>Фиксация одной коронки на стеклоиномерный цемент, изготовленной в других мед. учреждениях</td>
            <td>1 500,00 р</td>
          </tr>
          <tr>
            <td>11.4</td>
            <td>Адгезивная факсация без металловых конструкций</td>
            <td>1 700,00 р</td>
          </tr>
          <tr>
            <td>11.5</td>
            <td>Адгезивная фиксация безметалловых конструкций, иготовленной в других мед.учреждениях</td>
            <td>1 950,00 р</td>
          </tr>
        </tbody>
      </table>
      <h4 className={styles.subtitle}>Снятия несъемных протезов</h4>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>12.1</td>
            <td>Извлечение анкерного штифта</td>
            <td>1 500,00 р</td>
          </tr>
          <tr>
            <td>12.2</td>
            <td>Извлечение инородного тела</td>
            <td>1 600,00 р</td>
          </tr>
          <tr>
            <td>12.3</td>
            <td>Снятия штампованной коронки</td>
            <td>850,00 р</td>
          </tr>
          <tr>
            <td>12.4</td>
            <td>Снятие коронки на цельнолитом каркасе</td>
            <td>900,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Цены на дополнительные услуги</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>13.0</td>
            <td>Осмотр с выдачей заключения (справки)</td>
            <td>1 100,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Пародонтология</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>14</td>
            <td>Медикаметозная обработка пародонтального кармана в области 1 зуба</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>14.1</td>
            <td>Медикаметозная обработка пародонтального кармана в области 1 сегмента</td>
            <td>4 400,00 р</td>
          </tr>
          <tr>
            <td>14.2</td>
            <td>Медикаметозная обработка пародонтального кармана в области 1 зуба купралом</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>14.3</td>
            <td>Инъекция в переходную складку в области одной челюсти</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>14.4</td>
            <td>Аппликация лекарственных веществ в области 1 зуба</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>14.5</td>
            <td>Инъекция лекарственных веществ в области 3-х зубов</td>
            <td>1 600,00 р</td>
          </tr>
          <tr>
            <td>14.6</td>
            <td>Введение лекарственных препаратов в пародонтальный карман</td>
            <td>1 100,00 р</td>
          </tr>
          <tr>
            <td>14.7</td>
            <td>Пародонтологическое лечение системой VEKTOR в области одного зуба</td>
            <td>950,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Шинирование</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>15</td>
            <td>Шинирование двух зубов композитным материалом</td>
            <td>1 600,00 р</td>
          </tr>
          <tr>
            <td>15.1</td>
            <td>Шинирование стекловолоконной системой (за 1 межзубной промежуток)</td>
            <td>5 500,00 р</td>
          </tr>
          <tr>
            <td>15.2</td>
            <td>Временное шинирование</td>
            <td>1 900,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Операция на пародонте</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>16</td>
            <td>Удаление пародонтальной кисты</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>16.1</td>
            <td>Кюретаж в области одного зуба</td>
            <td>900,00 р</td>
          </tr>
          <tr>
            <td>16.2</td>
            <td>Гингивэктомия, гингивотомия в области 2-х зубов</td>
            <td>1 250,00 р</td>
          </tr>
          <tr>
            <td>16.3</td>
            <td>Лоскутная операция в области 1 зуба</td>
            <td>2 450,00 р</td>
          </tr>
          <tr>
            <td>16.4</td>
            <td>Лоскутная операция с компактостеотомией, компактостеоэктомией в области одного зуба</td>
            <td>2 550,00 р</td>
          </tr>
          <tr>
            <td>16.5</td>
            <td>Пластика десны в области 1 зуба</td>
            <td>2 450,00 р</td>
          </tr>
          <tr>
            <td>16.6</td>
            <td>Открытый кюретаж в области 1 зуба</td>
            <td>1 800,00 р</td>
          </tr>
          <tr>
            <td>16.7</td>
            <td>Вестибулопластика при пародонтите</td>
            <td>7 300,00 р</td>
          </tr>
          <tr>
            <td>16.8</td>
            <td>Закрытый кюретаж патологического зубодесневого кармана в области 1 зуба</td>
            <td>2 300,00 р</td>
          </tr>
          <tr>
            <td>16.9</td>
            <td>Закрытый кюретаж патологического зубодесневого кармана в области 3-х зубов</td>
            <td>5 200,00 р</td>
          </tr>
          <tr>
            <td>16.10</td>
            <td>Закрытый кюретаж патологического зубодесневого кармана в области 1 сегмента</td>
            <td>10 800,00 р</td>
          </tr>
          <tr>
            <td>16.11</td>
            <td>Открытый кюретаж патологического зубодесневого кармана в области 1 зуба</td>
            <td>3 250,00 р</td>
          </tr>
          <tr>
            <td>16.12</td>
            <td>Открытый кюретаж патологического зубодесневого кармана в области 3-х зубов</td>
            <td>6 300,00 р</td>
          </tr>
          <tr>
            <td>16.13</td>
            <td>Открытый кюретаж патологического зубодесневого кармана в области 1 сегмента</td>
            <td>13 350,00 р</td>
          </tr>
          <tr>
            <td>16.14</td>
            <td>Пластика рецессии десны простая</td>
            <td>6 300,00 р</td>
          </tr>
          <tr>
            <td>16.15</td>
            <td>Пластика рецессии десны со смещением лоскута</td>
            <td>12 450,00 р</td>
          </tr>
          <tr>
            <td>16.16</td>
            <td>Забор и пересадка соединительнно-тканного лоскута с неба</td>
            <td>12 450,00 р</td>
          </tr>
          <tr>
            <td>16.17</td>
            <td>Пластика тяжей слизистой (1 тяж)</td>
            <td>6 300,00 р</td>
          </tr>
          <tr>
            <td>16.18</td>
            <td>Гингивэктомия в области 1 зуба</td>
            <td>3 300,00 р</td>
          </tr>
          <tr>
            <td>16.19</td>
            <td>Удаление коронковой части зуба</td>
            <td>6 300,00 р</td>
          </tr>
          <tr>
            <td>16.20</td>
            <td>Пластика межзубного сосочка</td>
            <td>6 300,00 р</td>
          </tr>
          <tr>
            <td>16.21</td>
            <td>Увеличение разм. альвеолярного гребня с исп. соединительно-тканного трансплантанта с неба</td>
            <td>2 650,00 р</td>
          </tr>
          <tr>
            <td>16.22</td>
            <td>Восстановление дефекта зубного ряда с использованием шины</td>
            <td>8 950,00 р</td>
          </tr>
          <tr>
            <td>16.23</td>
            <td>Сглаживание поверхности корня во время операции</td>
            <td>1 250,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.title}>Пародонтальные повязки</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>17</td>
            <td>Наложение защитной повязки в области 1 зуба (солкосерил)</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>17.1</td>
            <td>Наложение защитной повязки в области 1 зуба (Диплен-дента)</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>17.2</td>
            <td>Наложение послеоперационной давящей повязки (воло-пак, септопак)</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>17.3</td>
            <td>Избирательное пришлифовывание в области зуба</td>
            <td>500,00 р</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Price;
